<template>
  <auth-card :loading="forgotFormProcessing"
    class="forgot-card"
    shadow="always"
    show-close-btn
    :loading-text="$t('authentication.processing-reset')">
    <template #description>
      <el-form ref="forgotForm"
        class="forgot-form"
        :model="forgotForm"
        :rules="forgotRules">
        <fieldset class="auth-fieldset">
          <legend>{{ $t('authentication.forgot') }}</legend>

          <el-form-item :label="$t('general.email')"
            prop="email">
            <el-input v-model="forgotForm.email"
              name="email"
              type="email"
              autocomplete="email"
              :placeholder="$t('general.email')"
              @keydown.enter.native.prevent="forgot" />
          </el-form-item>

          <div class="text-align-center mt-2">
            <el-button class="full-width"
              type="primary"
              @click="forgot">
              {{ $t('authentication.reset') }}
            </el-button>

            <h5 class="or">
              <span>{{ $t('authentication.or') }}</span>
            </h5>

            <el-button class="full-width"
              @click="$router.push('/login/username', () => {})">
              {{ $t('authentication.log-in') }}
            </el-button>
          </div>
        </fieldset>
      </el-form>
    </template>
  </auth-card>
</template>

<script>
import captureSentryError from '@/utils/CaptureSentryError.js'
import { showModalAlert } from '@/components/authentication/AuthHelpers.js'
import sdk from '@megaport/api-sdk'
import AuthCard from '@/components/ui-components/AuthCard.vue'

export default {

  components: {
    'auth-card': AuthCard,
  },

  data() {
    return {
      forgotForm: {
        email: localStorage.getItem('_username') || '',
      },
      forgotRules: {
        email: [
          { required: true, message: this.$t('validations.required', { thing: 'Email' }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
      },
      forgotFormProcessing: false,
    }
  },
  methods: {
    forgot() {
      this.$refs['forgotForm'].validate(valid => {
        if (!valid) return

        this.forgotFormProcessing = true
        sdk.instance
          .passwordRequest(this.forgotForm.email)
          .then(() => {
            this.forgotFormProcessing = false
            // Look into showModalAlert function
            showModalAlert(this.$t('authentication.reset-password-sent-if-confirmed'), this.$t('authentication.password-reset-title'))
            this.$router.push('/login', () => {
              // empty function is intentional
            })
          })
          .catch(e => {
            this.forgotFormProcessing = false
            if (!e.handled) {
              if (e.data?.message) {
                showModalAlert(e.data.message, this.$t('authentication.reset-request-failed'), 'error')
              } else {
                showModalAlert(this.$t('authentication.unknown-error-reset'), this.$t('authentication.reset-request-failed'), 'error')
                captureSentryError(e)
              }
            }
          })
      })
    },
  },
}
</script>


<style lang="scss" scoped>
.forgot-card {
  h3 {
    font-size: 14px;
  }

  p.text-divider {
    display: flex;
    flex-direction: row;
    color: #414141;
    margin: 2rem auto;
    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid #ebeef5;
      margin: auto;
    }

    &:before {
      margin-right: 1rem;
    }

    &:after {
      margin-left: 1rem;
    }
  }
}

.forgot-card::v-deep .el-card__header {
  border: none;
  padding-bottom: 1rem;
}
</style>
